import React from 'react';
import sitemap from '../components/Layout/Navigation/sitemap';
import { SectionTemplate, Stack } from '../components';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { Heading } from '@cheapreats/react-ui';

const Sitemap = () => {
    return (
        <SectionTemplate
            header={
                <Heading color="primary" type="h1" margin="0 auto 20px auto">
                    Sitemap
                </Heading>
            }
        >
            <Stack wrap="wrap">
                {sitemap.map((category) => (
                    <CategoryWrapper key={category.label}>
                        <Heading type="h3" color="black" margin="20px auto">
                            {category.label}
                        </Heading>
                        <Stack direction="column">
                            {category.items.map((route) => (
                                <StyledLink
                                    to={route.to ? route.to : route.href}
                                    key={route.label}
                                >
                                    {route.label}
                                </StyledLink>
                            ))}
                        </Stack>
                    </CategoryWrapper>
                ))}
            </Stack>
        </SectionTemplate>
    );
};

const Space = styled.div`
    height: 5px;
`;

const Container = styled.div`
    padding: 1rem;
    text-align: center;
`;

const StyledLink = styled((props) => <Link {...props} />)`
    ${({ theme }) => `
        color: ${theme.colors.text};
        &:hover {
            color: ${theme.colors.primary};
        }
    `}
    text-decoration: none;
`;

const CategoryWrapper = styled.div`
    flex: 1 1 20%;
`;

export default Sitemap;
